import React from "react";
import "./App.css";
import Game from "./main/game";

function App() {
  new Game();
  return null;
}

export default App;
